
body { width: 100vw; height: 100vh; overflow: hidden; font-family: 'Futura'; font-weight: normal; font-style: normal; background: #2e2e2e; }



.switchers.hidden { right: -500px; }

.switchers { position: fixed; top: 30px; right: -25px; z-index: 9999; background: #121212; padding: 10px 30px 10px 10px; border-radius: 10px; width: 500px; }

.switchers .trigger { position: absolute; top: 0; left: -50px;  background: #121212; padding: 12px 25px 13px 10px; border-radius: 10px; height: 47px; }
.switchers .trigger:hover .line { background: #fb7c2b; cursor: pointer; }
.switchers .trigger .line { background: #fff; width: 30px; height: 2px; margin-bottom: 8px; position: relative; top: 0; }
.switchers .trigger .line.line-btt { margin-bottom: 0; }
.switchers.unhidden .trigger .line.line-top { transform: rotate(45deg); top: 10px; }
.switchers.unhidden .trigger .line.line-mid { margin-bottom: 0; opacity: 0; }
.switchers.unhidden .trigger .line.line-btt { transform: rotate(-45deg); top: -2px; }


.switchers .trigger-fetch { position: absolute; top: 55px; left: -30px;  background: #121212; padding: 8px 15px 8px 8px; border-radius: 10px; }
.switchers .trigger-fetch .fetcher { height: 15px; width: 15px; background: #2ecc71; border-radius: 50%; } 
.switchers .trigger-fetch .fetcher.is-fetching { background: #cf000f; animation: blinker 100ms linear infinite; } 

.switchers .content { padding: 0 10px 10px 10px; }

.switchers .content p { margin-bottom: 10px; font-family: 'Futura'; cursor: pointer; }
.switchers .content p:last-of-type { margin-bottom: 0; }
.switchers .content p span { display: block; }
.switchers .content p span.title { font-weight: bold; font-size: 14px; line-height: 20px; color: #fb7c2b; }
.switchers .content p span.desc { font-weight: normal; font-size: 14px; line-height: 18px; color: #fff; }
.switchers .content p:hover span.title { color: lime; }


.slider-control { position: fixed; bottom: 30px; right: -25px; z-index: 9999; background: #121212; padding: 10px 30px 10px 10px; border-radius: 10px; width: 190px; }
.slider-control.hidden { right: -190px; }

.slider-control .trigger { position: absolute; top: 0; left: -50px;  background: #121212; padding: 12px 25px 13px 10px; border-radius: 10px; height: 47px; }
.slider-control .trigger:hover .line { background: #fb7c2b; cursor: pointer; }
.slider-control .trigger .line { background: #fff; width: 30px; height: 2px; margin-bottom: 8px; position: relative; top: 0; }
.slider-control .trigger .line.line-btt { margin-bottom: 0; }
.slider-control.unhidden .trigger .line.line-top { transform: rotate(45deg); top: 10px; }
.slider-control.unhidden .trigger .line.line-mid { margin-bottom: 0; opacity: 0; }
.slider-control.unhidden .trigger .line.line-btt { transform: rotate(-45deg); top: -2px; }

.slider-control .col-control { width: 35px; padding: 5px; display: inline-block; }
.slider-control .col-control .row-control { padding: 5px; }
.slider-control .col-control .row-control span { display: block; height: 15px; width: 15px; background: #fff; border-radius: 50%; margin: 0 auto; }
.slider-control .col-control .row-control span.act { background: #fb7c2b; cursor: pointer; }
.slider-control .col-control .row-control span:hover { background: lime; cursor: pointer; }
.slider-control .col-control .row-control span.feat { background: transparent !important; position: relative; }
.slider-control .col-control .row-control span.feat span { background: transparent !important; color: #fff; font-size: 42px; line-height: 42px; text-align: center; position: absolute; top: -5px; left: -1px; }

.slider-control .col-control .row-control span:hover.feat span { color: lime; }
.slider-control .col-control .row-control span.act.feat span { color: #fb7c2b; }

.new-version { position: absolute; top: 0; left: 0; background: transparent; padding: 3vh 6vh; z-index: 999; width: 100%; background: rgba(0,0,0,0.7); height: 100%; 
 margin: 0; z-index: 1; height: 100vh; max-width: calc(100vh / var( --screen-convert-ratio ) ); width: 100vw;
}
.new-version .inner { width: 35%; margin: 0; background: #fff; padding: 2vh; border-radius: 10px; }
.new-version .inner p { color: #121212; font-size: calc( 16vh * var( --font-convert-ratio ) ); font-height: calc( 22vh * var( --font-convert-ratio ) );
         margin-bottom: calc( 15vh * var( --font-convert-ratio ) ); }
.new-version .inner p em { opacity: 0.6; }

.error a,
.new-version .inner button { border-radius: calc( 18vh * var( --font-convert-ratio ) ); font-size: calc( 14vh * var( --font-convert-ratio ) ); 
    text-align: center; display: inline-block; cursor: pointer !important; line-height: calc( 36vh * var( --font-convert-ratio ) ); text-align: center; padding: 0 calc( 14vh * var( --font-convert-ratio ) ); 
    color: #fff; background: #FF8A22; font-weight: bold; height: calc( 36vh * var( --font-convert-ratio ) ); }


.error { background: #fff; width: calc( 12vh / var( --font-convert-ratio ) ); z-index: 99999; padding: 4vh; font-size: calc( 20vh * var( --font-convert-ratio ) );
    line-height: calc( 24vh * var( --font-convert-ratio ) ); left: calc( 4vh / var( --font-convert-ratio ) ); }
.error h3 { margin-bottom:  calc( 10vh * var( --font-convert-ratio ) ); }
.error p { margin-bottom: calc( 15vh * var( --font-convert-ratio ) ); }
.error p + p { position: relative; margin-bottom: calc( 10vh * var( --font-convert-ratio ) ); top: calc( -10vh * var( --font-convert-ratio ) ); opacity: 0.6; }


.cnt-info { position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgba(12,12,12,0.8); z-index: 99999; border: 5vh solid #fff;  padding: 6vh; }
.cnt-info ul { font-size: calc( 18vh * var( --font-convert-ratio ) ); line-height: calc( 26vh * var( --font-convert-ratio ) );}
.cnt-info ul li { color: #fff; }
.cnt-info ul li span { color: lime; }




/*
.screens { opacity: 0.6; position: absolute; left: 5vh; pointer-events: none; overflow: hidden; zoom: 0.48; border: 10px solid fuchsia; }
.screens.active { opacity: 1; pointer-events: all; border: 10px solid lime; }    
.screens.screen-one { top:2vh; }
.screens.screen-two { bottom:2vh; }
.screens .fade { opacity: 1 !important; }
*/

.screens { opacity: 0; position: absolute; left: 0; top:0; pointer-events: none; overflow: hidden; }
.screens.active { opacity: 1; pointer-events: all; }    
